import React, { memo, useState, useEffect } from 'react'
import cn from 'classnames'

import { IUser } from '../../types/user'
import UserCheckouts from './UserCheckouts'
import UserForm, {IOwnProps, IUserInfoProps} from './UserForm'
import UserView from "./UserView";

type UserInfoTab = 'info' | 'history' | 'groups'

const filters: Array<{ name: string; type: UserInfoTab }> = [
  { name: 'Details', type: 'info' },
  { name: 'History', type: 'history' },
]

const UserInfo = ({
  user,
  account,
  branch,
  onSave,
  loading,
  filter,
  isEditing,
  setIsEditing,
  ...rest
}: IUserInfoProps<IOwnProps>) => {
  const [tab, setTab] = useState<UserInfoTab>('info')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setIsEditing?.(false)
    setTab('info')
  }, [user.id])

  const isNew = !user.id

  const onFinish = async (user: IUser | { password?: string }) => {
    setSaving(true)
    await onSave(user)
    setSaving(false)
  }

  return (
    <div className="user-info">
      <div className="user-info_info">
        <div className={`user-info_title ${isNew || isEditing ? "-separated" : ""}`}>
          {isNew ? (
            <div className="user-info_form_title">Add new user</div>
          ) : isEditing ? (
            <div className="user-info_form_title">Edit user</div>
          ) : (
            <>
              <div className="user-info_sections">
                {filters.map(f => (
                  <div
                    key={f.type}
                    className={cn('user-info_toggler', {
                      '-active': f.type === tab,
                    })}
                    onClick={() => setTab(f.type)}
                  >
                    {f.name}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {tab === 'history' && (
          <UserCheckouts
            user={user}
            branchId={branch.id}
            accountId={account && account.id}
          />
        )}
        {tab === 'info' && (
          isEditing || isNew ? (
            <UserForm
              user={user}
              branch={branch}
              onSave={onFinish}
              loading={loading}
                filter={filter}
                {...rest}
              />
            ) : (
            <UserView
              user={user}
              branch={branch}
              onSave={onFinish}
              loading={loading}
              filter={filter}
              setIsEditing={setIsEditing}
              {...rest}
            />
          )
        )}
      </div>
    </div>
  )
}

export default memo(UserInfo)
