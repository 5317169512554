import { sortBy, unionBy } from 'lodash'
import React, { ReactNode } from 'react'
import Tooltip from '../../components/Tooltip'
import { IUser } from '../../types/user'

export const role = (user: IUser) => {
  if (user.role === 'technician')
    return 'Technician'

  if (user.role === 'planner')
    return 'Planner'

  if (user.generalManager && user.checkoutManager)
    return 'General And Checkout Manager'

  if (user.checkoutManager)
    return 'Checkout Manager'

  if (user.generalManager)
    return 'General Manager'

  if (user.role === 'manager')
    return 'Manager'

  return 'Unknown Role'
}

const tooltipList = (list: any[], title: string) => (
  <div className="vanilla-tooltip">
    <span>
      {list.length}
      &nbsp;
      {title}
    </span>
    <span className="tooltiptext">
      {list.map(o => (
        <div key={o.id}>{o.name}</div>
      ))}
    </span>
  </div>)

export const info = (user: IUser) => {
  const data: ReactNode[] = []
  const divisions = sortBy(unionBy(user.divisions || [], 'name'), 'name')
  const teams = sortBy(unionBy(user.teams || [], 'name'), 'name')

  if (divisions.length === 1) {
    data.push(<>{divisions[0].name}</>)
  }
  if (divisions.length > 1) {
    data.push(tooltipList(divisions, 'Divisions'))
  }

  if (teams.length === 1) {
    data.push(<>{teams[0].name}</>)
  }
  if (teams.length > 1) {
    data.push(tooltipList(teams, 'Teams'))
  }

  return data
}
