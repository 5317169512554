import React, { FC, memo, useEffect, useState } from 'react'
import cn from 'classnames'

import { IBranch, IDivision, ITeam, IUser } from '../../../types/user'
import { IHash } from '../../../types/common'
import TeamsForm from './TeamsForm'
import UserDivisionsForm from './UserDivisionsForm'

interface IUserFormProps {
  user: IUser
  divisions: IDivision[]
  teams: ITeam[]
  loading: boolean
  errors: IHash<string>
  onDelete?: (user: IUser) => void
  onCancel?: () => void
  onPasswordUpdate?: (user: IUser, password: string) => void
  onBranchUpdate?: (user: IUser, branch: IBranch) => void,
  setUser: (user: IUser) => void
}

const GroupsForm: FC<IUserFormProps> = ({
  user,
  divisions,
  teams,
  setUser,
}) => {
  return (
    <div>
      <div>
        <TeamsForm user={user} teams={teams} setUser={setUser}/>
      </div>

      {user.role === 'technician' && (
        <>
          <div className="user-form_full_width_border"></div>

          <div>
            <UserDivisionsForm user={user} divisions={divisions} setUser={setUser}/>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(GroupsForm)
