import React, {memo, useState, useCallback, useEffect, SetStateAction, Dispatch, useRef} from 'react'
import cn from 'classnames'
import { format } from 'date-fns'
import { IUser, IDivision, IBranch, IAccount, ITeam, UserRole } from '../../types/user'
import { IHash } from '../../types/common'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Toggle from '../../components/Toggle'
import Tooltip from '../../components/Tooltip'
import LockIcon from '../../icons/lock_filled.svg'
import UserChangePassword from './UserChangePassword'
import UserBranchMove from './UserBranchMove'
import DropdownSelect from "../../components/DropdownSelect";
import GroupsForm from "./GroupsForm";

export interface IOwnProps {
  user: IUser
  account?: IAccount
  divisions: IDivision[]
  teams: ITeam[]
  branch: IBranch
  loading: boolean
  errors: IHash<string>
  onSave: (user: IUser | { password?: string }) => Promise<void>
  onDelete?: (user: IUser) => void
  onCancel?: () => void
  onPasswordUpdate?: (user: IUser, password: string) => void
  onBranchUpdate?: (user: IUser, branch: IBranch) => void
}

export interface IUserInfoProps<T> extends IOwnProps {
  filter?: string,
  isEditing?: boolean,
  setIsEditing?: Dispatch<SetStateAction<boolean>>
}

interface IUserRolesOption {
  label: string
  value: string
}

export const USER_ROLES: IUserRolesOption[] = [
  { label: 'Manager', value: 'manager' },
  { label: 'General Manager', value: 'general_manager' },
  { label: 'Checkout Manager', value: 'checkout_manager' },
  { label: 'General and Checkout Manager', value: 'general_and_checkout_manager' },
  { label: 'Technician', value: 'technician' },
  { label: 'Planner', value: 'planner' },
]

export enum UserStatus {
  Active = "active",
  Pending = "invited",
  Inactive = "inactive",
}

const getInitialUserRole = (user: IUser): UserRole => {
  return user.generalManager && user.checkoutManager ?
    "general_and_checkout_manager"
    : user.generalManager
    ? "general_manager"
    : user.checkoutManager
      ? "checkout_manager"
      : user.role
        ? user.role : "technician";
};

const UserForm = ({
  user: initialUser,
  branch,
  loading,
  filter,
  errors,
  onSave,
  onDelete,
  onCancel,
  onPasswordUpdate,
  onBranchUpdate,
  divisions,
  teams,
}: IUserInfoProps<IOwnProps>) => {
  const [user, setUser] = useState<IUser>({ ...initialUser })
  const [password, setPassword] = useState<string>('')
  const [userRole, setUserRole] = useState<UserRole>(getInitialUserRole(initialUser))

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(
    false
  )
  const [moveVisible, setMoveVisible] = useState<boolean>(false)

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setUser({ ...user, password })
      onSave({ ...user, password })
    },
    [user, password]
  )

  const handleDelete = useCallback(() => {
    if (user.isRemovable && onDelete) {
      const confirmMessage = `Are you sure you want to delete ${user.name}?`;
      if (window.confirm(confirmMessage)) {
        onDelete(user);
      }
    }
  }, [user])

  useEffect(() => {
    const selectedUserRole = (isNew && filter !== 'all' && filter !== 'inactive') ?
      filter === 'generalAndCheckoutManagers'
      ? 'general_and_checkout_manager' : filter === 'technicians'
        ? 'technician' : filter === 'generalManagers'
          ? 'general_manager' : filter === 'checkoutManagers'
            ? 'checkout_manager' : filter === 'managers'
              ? 'manager' : 'planner'
    : getInitialUserRole(initialUser)

    setUserRole(selectedUserRole as UserRole)
    setPassword('')
    setUser({ ...initialUser, role: selectedUserRole })
  }, [initialUser.id])

  const isNew = !user.id;

  const handleRoleChange = (selectedRole: string) => {
    const isCheckoutManager = ['checkout_manager', 'general_and_checkout_manager'].includes(selectedRole);
    const isGeneralManager = ['general_manager', 'general_and_checkout_manager'].includes(selectedRole);
    setUser({
        ...user,
        role: selectedRole as UserRole,
        checkoutManager: isCheckoutManager,
        generalManager: isGeneralManager,
    });
    setPassword('')
    setUserRole(selectedRole as UserRole);
  };

  return (
    <form className="user-form" onSubmit={handleSubmit}>
      <div className="user-form_info">
        <div className={cn('user-form_field', {'-error': errors.name})}>
          <Input
            label="name"
            ref={nameInputRef}
            error={errors.name}
            value={user.name}
            onChange={e => setUser({...user, name: e.target.value})}
          />
        </div>

        <div className={cn('user-form_field', {'-error': errors.role})}>
          <DropdownSelect<string | undefined>
            label="Role"
            value={userRole}
            options={USER_ROLES as any}
            onChange={handleRoleChange}
            className="-md"
            dropdownClassName="input"
            dropdownValueClassName="input_input -flex white-bg"
          />
        </div>

        <div className={cn('user-form_field', {'-error': errors.email})}>
          <Input
            label="email"
            error={errors.email}
            value={user.email}
            onChange={e => setUser({...user, email: e.target.value})}
          />
        </div>

        {isNew && user.role === 'technician' && (
          <div className={cn('user-form_field', {'-error': errors.password})}>
            <Input
              label="password"
              placeholder="5+ symbols"
              error={errors.password}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        )}

        {!isNew && user.status !== UserStatus.Pending && (
          <div className="user-form_box -slim">
            <Toggle
              value={user.status === UserStatus.Active }
              onChange={() => setUser({...user,
                status: user.status === UserStatus.Active ? UserStatus.Inactive : UserStatus.Active})}
              option={{
                value: true,
                label: 'Active User',
              }}
            />
          </div>
        )}

        {!isNew && user.role === 'technician' && (
          <>
            <div className="user-form_box">
              <div className="user-form_text -inline">At {branch.name}</div>
              <Tooltip id="user-form-move">
                <div
                  className={cn('user-form_text -primary -link', {
                    '-disabled': !user.isMovable,
                  })}
                  data-tip={
                    !user.isMovable
                      ? 'You need to complete all user requests in mobile app to move user to another branch'
                      : null
                  }
                  data-for="user-form-move"
                  data-effect="solid"
                  data-place="top"
                  onClick={() => user.isMovable && setMoveVisible(true)}
                >
                  Move to another branch
                </div>
              </Tooltip>
            </div>

            <div className="user-form_box">
              <div className="user-form_text">
                {user.lastSignInAt
                  ? `Last sign in ${format(user.lastSignInAt, 'MM/DD hh:mm A')}`
                  : 'Never signed in'}
              </div>
              <div
                className="user-form_text -danger -link"
                onClick={() => setChangePasswordVisible(true)}
              >
                Change password
              </div>
            </div>
          </>
        )}
      </div>

      <div className="user-form_full_width_border"></div>

      <GroupsForm
        user={user}
        setUser={setUser}
        loading={loading}
        errors={errors}
        divisions={divisions}
        teams={teams}
      />

      <div className="user-form_footer">
        {!isNew && (
          <Tooltip id="user-form-delete">
            <div
              className={`user-form_delete ${!user.isRemovable ? "-disabled" : ""}`}
              onClick={handleDelete}
              data-tip={
                !user.isRemovable
                  ? 'This user can’t be deleted but you can deactivate the user'
                  : null
              }
              data-for="user-form-delete"
              data-effect="solid"
              data-place="top"
            >
              {!user.isRemovable && <LockIcon className="user-form_icon"/>}
              Delete
            </div>
          </Tooltip>
        )}
        <div className="user-form_actions-right">
          <div className="user-form_cancel" onClick={onCancel}>
            Cancel
          </div>
          <Button color="success" type="submit" loading={loading}>
            {isNew ? "Send Invite" : "Save"}
          </Button>
        </div>
      </div>

      <UserChangePassword
        opened={changePasswordVisible}
        onUpdate={password =>
          onPasswordUpdate && onPasswordUpdate(user, password)
        }
        onClose={() => setChangePasswordVisible(false)}
      />

      <UserBranchMove
        branch={branch}
        opened={moveVisible}
        onUpdate={(branch: IBranch) =>
          onBranchUpdate && onBranchUpdate(user, branch)
        }
        onClose={() => setMoveVisible(false)}
      />
    </form>
  )
}

export default memo(UserForm)
