export const USERS_QUERY = `
{
  internal {
    internals {
      id
      name
      avatar
      email
      lastSignInAt
    }
  }
}
`
