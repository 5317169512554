import {flow, get, map, pick, sortBy} from 'lodash/fp'
import {ThunkAction} from '../../types/common'
import {query} from '../../common/api'
import {IBranch, IDivision} from '../../types/user'
import {IInventoryEvent, IProduct} from '../../types/products'
import {
  BRANCHES_PRODUCTS_LIST_QUERY,
  INTERNAL_BRANCHES_PRODUCTS_LIST_QUERY,
  INTERNAL_PRODUCTS_QUERY,
  INTERNAL_SALABLE_PRODUCTS_LIST_QUERY,
  INVENTORY_EVENTS_INTERNAL_QUERY,
  INVENTORY_EVENTS_MANAGER_QUERY,
  PRODUCT_CREATE_QUERY,
  PRODUCT_REMOVE_QUERY,
  PRODUCT_TRANSFER_QUERY,
  PRODUCT_UPDATE_QUERY,
  PRODUCTS_QUERY,
  SALABLE_PRODUCTS_LIST_QUERY,
} from './inventoryQueries'

export interface ISearchDivisions {
  [key: string]: IProduct[]
}

export interface IInventoryList {
  products: ISearchDivisions
  divisions: IDivision[]
  distributors: string[]
}

export const requestInventory = (
  branchId: number,
  accountId?: number
): ThunkAction<Promise<IInventoryList>> => async dispatch => {
  const isInternal = accountId != null
  const response = isInternal
    ? await query(INTERNAL_PRODUCTS_QUERY, { branchId, accountId })
    : await query(PRODUCTS_QUERY, { branchId })
  const products = (isInternal
    ? get(
        'internal.accounts.nodes[0].currentBranch[0].inventoryProducts',
        response
      )
    : get('manager.currentBranch[0].inventoryProducts', response)) as IProduct[]

  const divisions = flow(
    isInternal
      ? get('internal.accounts.nodes[0].divisions')
      : get('manager.account.divisions'),
    sortBy<IDivision>('name')
  )(response) as IDivision[]

  const distributors = (isInternal
    ? get('internal.accounts.nodes[0].distributorsList', response)
    : get('manager.account.distributorsList', response)) as string[]

  const groups = divisions.reduce(
    (acct, division) => {
      const gr = products.filter(t =>
        (t.divisions || [])
          .map((d: { id?: number }) => d.id)
          .includes(division.id)
      )
      return {
        ...acct,
        [`${division.name}-${division.id}`]: gr,
      }
    },
    {
      all: products,
      unallocated: products.filter(p => !p.divisions.length),
      lowstock: products.filter(
        p =>
          p.notifyThreshold &&
          p.threshold &&
          p.threshold >= Number(p.inventory)
      ),
      archived: [],
    }
  )

  return {
    products: groups,
    divisions,
    distributors,
  }
}

export const requestSalableProductsList = (
  branchId: number,
  code?: string,
  name?: string,
  accountId?: number
): ThunkAction<Promise<IProduct[]>> => async dispatch => {
  const isInternal = accountId != null
  const response = isInternal
    ? await query(INTERNAL_SALABLE_PRODUCTS_LIST_QUERY, {
        accountId,
        branchId,
        textCode: code,
        textName: name,
      })
    : await query(SALABLE_PRODUCTS_LIST_QUERY, {
        branchId,
        textCode: code,
        textName: name,
      })
  return (isInternal
    ? get('internal.accounts.nodes[0].salableCatalog', response)
    : get('manager.account.salableCatalog', response)) as IProduct[]
}

export const requestBranchesProductsList = (
  branchId: number,
  code?: string,
  name?: string,
  accountId?: number
): ThunkAction<Promise<IProduct[]>> => async dispatch => {
  const isInternal = accountId != null
  const response = isInternal
    ? await query(INTERNAL_BRANCHES_PRODUCTS_LIST_QUERY, {
        accountId,
        branchId,
        textCode: code,
        textName: name,
      })
    : await query(BRANCHES_PRODUCTS_LIST_QUERY, {
        branchId,
        textCode: code,
        textName: name,
      })
  return (isInternal
    ? get('internal.accounts.nodes[0].branchesCatalog', response)
    : get('manager.account.branchesCatalog', response)) as IProduct[]
}

export const requestInventoryEvents = (
  productId: number,
  branchId: number,
  accountId?: number
): ThunkAction<Promise<IInventoryEvent[]>> => async dispatch => {
  const isInternal = !!accountId
  const response = isInternal
    ? await query(INVENTORY_EVENTS_INTERNAL_QUERY, {
        branchId,
        accountId,
        productId,
      })
    : await query(INVENTORY_EVENTS_MANAGER_QUERY, { branchId, productId })

  const inventoryEvents = (isInternal
    ? get(
        'internal.accounts.nodes[0].currentBranch[0].inventoryProducts[0].inventoryEvents',
        response
      )
    : get(
        'manager.currentBranch[0].inventoryProducts[0].inventoryEvents',
        response
      )) as IInventoryEvent[]

  return inventoryEvents
}

export const requestProductUpsert = (
  product: IProduct,
  branch: IBranch
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  const { id, divisions, code, ...productAttributes } = product

  if (
    !product.unit ||
    !product.code ||
    !product.quantity ||
    !product.name ||
    (!product.price && product.price !== 0) ||
    (!product.inventory && product.inventory !== 0)
  ) {
    const errors = {
      unit: !product.unit ? 'required' : '',
      code: !product.code ? 'required' : '',
      quantity: !product.quantity ? 'required' : '',
      name: !product.name ? 'required' : '',
      price: !product.price ? 'required' : '',
      inventory:
        !product.inventory && product.inventory !== 0 ? 'required' : '',
    }

    return { errors }
  }

  if (
    product.notifyThreshold &&
    !product.threshold &&
    product.threshold !== 0
  ) {
    return { threshold: 'required' }
  }

  const divisionIds = map('id', divisions)
  const params = {
    ...pick(
      [
        'distributor',
        'name',
        'quantity',
        'inventory',
        'threshold',
        'notifyThreshold',
        'unit',
      ],
      productAttributes
    ),
    price: product.price && `${product.price}`,
    divisionIds,
  }

  return id
    ? get(
      'inventoryProductUpdate',
      await query(PRODUCT_UPDATE_QUERY, {
        branchId: branch.id,
        productId: id,
        attributes: params,
      })
    )
    : get(
      'inventoryProductCreate',
      await query(PRODUCT_CREATE_QUERY, {
        attributes: {
          productCode: code,
          ...params,
          branchId: branch.id,
        },
      })
    )
}

export const requestProductRemove = (
  product: IProduct,
  branch: IBranch
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  const response = await query(PRODUCT_REMOVE_QUERY, {
    branchId: branch.id,
    productId: product.id,
  })
  return response.errors
}

export const requestProductTransfer = (
  productCode: string,
  sourceBranchId: number,
  destinationBranchId: number,
  sourceAmount: number,
  destinationAmount: number
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  if (
    !sourceAmount ||
    !destinationAmount ||
    !productCode ||
    !sourceBranchId ||
    !destinationBranchId
  ) {
    return { base: 'All fields required' }
  }

  if (sourceBranchId === destinationBranchId) {
    return { base: 'Target branch the same' }
  }

  return get(
    'InventoryProductTransfer',
    await query(PRODUCT_TRANSFER_QUERY, {
      sourceBranchId,
      destinationBranchId,
      productCode,
      attributes: {
        sourceAmount,
        destinationAmount,
      },
    })
  )
}
