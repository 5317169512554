import { default as React, FunctionComponent } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import PlanProduct from '../PlanProduct'
import cn from 'classnames'
import { IProduct } from '../../types/products'
import { Link } from 'react-router-dom'
import useThunkDispatch from '../../common/useThunkDispatch'
import BasePopover from '../../components/BasePopover'
import useTimeoutOpen from '../../common/useTimeoutOpen'
import { requestAddPlanProduct, requestRemovePlanProduct } from '../planActions'
import ActionButton, {
  ActionButtonWrapper,
} from '../../components/ActionButton'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import { IAccount, IBranch } from '../../types/user'

interface IOwnProps {
  inPlan: boolean
  product: IProduct
  setUpdating: (isUpdating: boolean) => void
  branch: IBranch
  account?: IAccount
  multiCatalogEnabled?: boolean
}

const PlanTableProduct: FunctionComponent<IOwnProps> = props => {
  const {
    product,
    setUpdating,
    inPlan,
    account: currentAccount,
    branch,
    multiCatalogEnabled,
  } = props

  const branchId = branch.id
  const accountId = currentAccount ? currentAccount.id : undefined
  // TODO: find the better way
  const link =
    accountId && branchId
      ? `/clients/${accountId}/branches/${branchId}/${product.id}`
      : `/product/${product.id}`

  const dispatch = useThunkDispatch()
  const [open, onOpen, onClose] = useTimeoutOpen(false, 0, 0)

  const onAdd = async () => {
    setUpdating(true)
    const errors = await dispatch(requestAddPlanProduct(branchId, product))
    setUpdating(false)
    if (errors) alert(errors)
  }

  const onRemove = async () => {
    setUpdating(true)
    const errors = await dispatch(requestRemovePlanProduct(branchId, product))
    setUpdating(false)
    if (errors) alert(errors)
  }

  return (
    <div className="replenishment_col -product">
      <ActionButtonWrapper className="replenishment_product">
        <PlanProduct className="-small" productOrOrderItem={product} multiCatalogEnabled={multiCatalogEnabled} />

        {inPlan ? (
          <Link
            to={link}
            className={cn(
              'replenishment_product-link clear-button',
              inPlan ? '-delete' : '-add'
            )}
          />
        ) : (
          <div
            className={cn(
              'replenishment_product-link clear-button',
              inPlan ? '-delete' : '-add'
            )}
          />
        )}

        <BasePopover
          preferPlace="below"
          isOpen={open}
          className="replcut -notice -auto"
          body={
            <div
              className="replcut_body"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
            >
              {inPlan ? (
                <div>
                  Remove item from upcoming
                  <br />
                  Replenishments
                </div>
              ) : (
                <div>
                  Add item to Replenishment
                  <br />
                  Plan
                </div>
              )}
            </div>
          }
        >
          <div className="replenishment_item-action">
            {inPlan ? (
              <ActionButton
                action="remove"
                onClick={onRemove}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              />
            ) : (
              <ActionButton
                action="add"
                onClick={onAdd}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              />
            )}
          </div>
        </BasePopover>
      </ActionButtonWrapper>
    </div>
  )
}

export default compose<any>(
  withRouter,
  withAccountAndBranch
)(PlanTableProduct)
