import React, { memo } from 'react'
import cn from 'classnames'
import Tooltip from '../../components/Tooltip'
import { IUser } from '../../types/user'
import GroupIcon from '../../icons/group.svg'
import { info, role } from './utils'

interface IOwnProps {
  user: IUser
  onClick: (user: IUser) => void
  className?: string
}

const UserCard: React.FC<IOwnProps> = ({ user, className, onClick }) => (
  <div className={cn('user-card', className)} onClick={() => onClick(user)}>
    <div className="user-card_info">
      <div className="user-card_name">{user.name}</div>
      <div className="user-card_details">
        <div className="user-card_email">{user.email}</div>
        {info(user).map((e, i) => (
          <React.Fragment key={i}>
            &nbsp;&bull;&nbsp;
            {e}
          </React.Fragment>
        ))}
      </div>
    </div>
    <div className="user-card_label">
      <div className="user-default_badge">{role(user)}</div>
    </div>
  </div>
)

export default memo(UserCard)
