import { default as React, FunctionComponent, memo, ReactNode } from 'react'
import cn from 'classnames'
import { IProduct } from '../types/products'
import PlaceholderIcon from '../components/OrderSnippet/placeholder.svg'
import { IOrderItem, isOrderItem } from '../types/orders'
import { money } from '../common/display'

interface IOwnProps {
  productOrOrderItem: IProduct | IOrderItem
  className?: '-small' | '-hero'
  children?: ReactNode
  multiCatalogEnabled?: boolean
}

const PlanProduct: FunctionComponent<IOwnProps> = ({
  productOrOrderItem,
  className,
  children,
  multiCatalogEnabled = false,
}) => {
  const product = isOrderItem(productOrOrderItem)
    ? productOrOrderItem.product
    : productOrOrderItem
  return (
    <div className={cn('order-snippet', className)}>
      <div className="order-snippet_col -info -large">
        <div className="order-snippet_image-wrapper">
          {product.photo ? (
            <img className="order-snippet_image" src={product.photo} alt="" />
          ) : (
            <PlaceholderIcon className="order-snippet_image" />
          )}
        </div>
        <div className="order-snippet_title">{product.name}</div>
        <div className="order-snippet_sku">{product.code}</div>
        {multiCatalogEnabled && (
          <div className="order-snippet_erp">
            • {product.erpCatalogName}
          </div>)
        }
        {product.price != null && product.salesUnit != null && (
          <div className="order-snippet_price">
            {money(product.price)}
            &nbsp;/&nbsp;
            {product.salesUnit}
          </div>
        )}
      </div>

      {children}
    </div>
  )
}

export default memo(PlanProduct)
