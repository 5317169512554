import {default as React, FunctionComponent, memo, useEffect, useRef} from 'react'
import { Formik } from 'formik'
import Button from '../../components/Button'
import { IDivision } from '../../types/user'
import useThunkDispatch from '../../common/useThunkDispatch'
import { updateManagerDivision } from '../managerActions'
import Input from '../../components/Input'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import CrossIcon from '../../icons/cross.svg'

interface IOwnProps {
  division: Partial<IDivision>
  branchId: number
  onClose(): void
  onSave(): void
}

const AccountDivisionEdit: FunctionComponent<IOwnProps> = ({
  division,
  branchId,
  onClose,
  onSave,
}) => {
  const dispatch = useThunkDispatch()
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const onSubmit = async (attrs: Partial<IDivision>, actions: any) => {
    actions.setSubmitting(true)
    try {
      const errors = await dispatch(
        updateManagerDivision({
          id: attrs.id,
          branchId,
          name: attrs.name,
        })
      )

      if (errors) {
        actions.setErrors(errors)
      } else {
        onSave()
        onClose()
      }
    } catch (e) {
      alert('Server Error')
    }
    actions.setSubmitting(false)
  }

  if (!division) return null

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />
      <div className="import_dialog user-modal -sm">
        <Formik
          initialValues={division}
          enableReinitialize={true}
          onSubmit={onSubmit}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            dirty,
          }) => (
            <form className="form -md" onSubmit={handleSubmit}>
              <div className="user-modal_title">
                {values.id ? 'Edit Division' : 'New Division'}
              </div>
              <CrossIcon className="user-modal_close" onClick={onClose} />
              <div className="user-modal_field">
                <Input
                  name="name"
                  ref={nameInputRef}
                  error={errors.name}
                  label="Name"
                  value={values.name || ''}
                  onChange={handleChange}
                />
              </div>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !dirty}
                color="primary"
                type="submit"
                className="user-modal_button"
              >
                Save
              </Button>
              <div className="user-modal_cancel" onClick={onClose}>
                Cancel
              </div>
            </form>
          )}
        />
      </div>
    </ModalWrapper>
  )
}

export default memo(AccountDivisionEdit)
