import React, { memo, useState } from 'react'
import {IUser, UserRole} from '../../types/user'
import {IOwnProps, IUserInfoProps, USER_ROLES} from "./UserForm";
import {format} from "date-fns";

const getUserRole = (user: IUser): string => {
  const roleValue: UserRole = user.generalManager && user.checkoutManager
    ? "general_and_checkout_manager" : user.generalManager
    ? "general_manager"
    : user.checkoutManager
      ? "checkout_manager"
      : user.role
        ? user.role
        : "technician";

  const role = USER_ROLES.find((role) => role.value === roleValue);

  return role ? role.label : "Unknown Role";
};

const UserView = ({
                    user,
                    setIsEditing,
                  }: IUserInfoProps<IOwnProps>) => {
  const userRole = getUserRole(user)

  const handleCopyEmail = () => {
    if (user.email) {
      navigator.clipboard.writeText(user.email)
        .then(() => {
          alert('Email copied to clipboard!');
        })
    }
  };

  const handleResendInvitation = async () => {
    try {
      const response = await fetch('/password/resend_invitation', {
        method: 'POST',
        body: JSON.stringify({user: {email: user.email}}),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok) {
        alert('Invitation resent')
      }

    } catch (e) {
      return 'Server error'
    }
  }

  const formatDateTime = (isoDateString: string | undefined) => {
    if (!isoDateString) return null;

    const date = new Date(isoDateString);

    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const UserStatusNames: Record<'pending' | 'active' | 'inactive', string> = {
    pending: 'invited',
    active: 'active',
    inactive: 'inactive',
  };

  return (
    <div>
      <div className="user-view_box">
        <div className="user-view_text">
          <div className="user-view_names">
            <div className="user-view_name">{user.name}</div>
            <div
              className="user-view_email"
              onClick={handleCopyEmail}
            >
              {user.email}
            </div>
          </div>

          <div className="user-view_badge">
            <div className="user-default_badge">{userRole}</div>
          </div>
        </div>

        <div className="user-view_invitation separated">
          <div className="user-view_signed_in">
            {user.status === 'pending'
              ? `Pending invitation ${formatDateTime(user.resetPasswordSentAt)}`
              : user.lastSignInAt ? `Last signed in on ${formatDateTime(user.lastSignInAt)}` : 'Never signed in'}
          </div>

          <div className="user-view_badge">
            <div className={`user-status_badge ${user.status}`}>{UserStatusNames[user.status as keyof typeof UserStatusNames]}</div>
          </div>
        </div>

        {user.role !== 'technician' && user.status === 'pending' && (
          <button
            className="user-view_resend"
            onClick={handleResendInvitation}
          >
            Resend invitation
          </button>
        )}
      </div>

      <div className="user-view_box">
        <div className="user-view_title">Teams</div>
        <div className="user-view_badge_list">
          {user.teams && user.teams.length > 0 ? user.teams.map(team => (
            <div key={team.id} className="user-default_badge">
              {team.name}
            </div>
          )) : <div className="user-view_signed_in">No teams</div>}
        </div>
      </div>

      {user.role === 'technician' && (
        <div className="user-view_box">
          <div className="user-view_title">Divisions</div>
          <div className="user-view_badge_list">
            {user.divisions && user.divisions.length > 0 ? user.divisions.map(division => (
              <div key={division.id} className="user-default_badge">
                {division.name}
              </div>
            )) : <div className="user-view_signed_in">No divisions</div>}
          </div>
        </div>
      )}

      <div className="user-view_footer">
        <button className="edit-user_button" onClick={() => setIsEditing?.(true)}>
          Edit user
        </button>
      </div>
    </div>
  )
}

export default memo(UserView)
