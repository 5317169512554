import React, { FC, memo, useState } from 'react'
import cn from 'classnames'
import { IProduct } from '../../types/products'
import { money } from '../../common/display'
import Tooltip from '../../components/Tooltip'
import ImagePlacehoderIcon from '../../icons/image-placeholder.svg'
import ProductRepackingIcon from '../../icons/poduct-repacking.svg'
import ProductNotifyThresholdIcon from '../../icons/threshold.svg'
import ProductExistsIcon from '../../icons/exists.svg'
import LogoIcon from '../../icons/logo.svg'

interface IProductCard {
  product: IProduct
  className?: string
  tiny?: boolean
  productExist?: boolean
  onClick?: () => void
  multiCatalogEnabled?: boolean
}

const ProductCard: FC<IProductCard> = ({
  product,
  className,
  productExist,
  tiny = false,
  onClick,
  multiCatalogEnabled = false,
}) => {
  const [imgPlaceholder, setImgPlacehoder] = useState(!product.photo)
  const isCustom = product.custom && !product.originalProductData
  const isLowstock =
    product.notifyThreshold &&
    product.threshold &&
    product.threshold >= Number(product.inventory)

  return (
    <div
      className={cn('product-card', className)}
      onClick={productExist ? undefined : onClick}
      style={productExist ? { cursor: 'not-allowed' } : undefined}
    >
      <div className="product-card_photo">
        {!imgPlaceholder && (
          <img
            src={product.photo}
            className="product-card_image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              setImgPlacehoder(true)
            }}
          />
        )}
        {imgPlaceholder && (
          <ImagePlacehoderIcon
            className="product-card_image"
            data={product.photo}
          />
        )}
      </div>
      <div className="product-card_info">
        <div className="product-card_section">
          <div className="product-card_name">{product.name}</div>
          <div className={isLowstock ? '-lowstock' : undefined}>
            {product.inventory}
          </div>
          {productExist && (
            <div className="product-card_text">
              <div className="product-card_badge -exists">
                <ProductExistsIcon />
                <span>already added</span>
              </div>
            </div>
          )}
        </div>
        <div className="product-card_section">
          <div className="product-card_text">
            {product.code}
            {multiCatalogEnabled && product.erpCatalogName && (
              <>
                &nbsp;•&nbsp;{product.erpCatalogName}
              </>
            )}
          </div>
          <div className="product-card_text">
            {product.notifyThreshold && (
              <div className="product-card_badge -threshold">
                <Tooltip id={`tooltip-threshold-${product.id}`}>
                  <div
                    className="input_label-tooltip"
                    data-tip="Low stock threshold is active"
                    data-for={`tooltip-threshold-${product.id}`}
                    data-effect="solid"
                    data-place="top"
                  >
                    <ProductNotifyThresholdIcon
                      data-tip="Notify when low stock"
                      data-for="product-card-notify_threshold-switch"
                      data-effect="solid"
                      data-place="top"
                      className="product-notify_threshold"
                    />
                  </div>
                </Tooltip>
                <span>{product.threshold}</span>
              </div>
            )}
            {product.price != null && product.unit != null && (
              <div
                className={`product-card_badge -repacking ${(product.quantity ||
                  1) <= 1 && '-no-icon'}`}
              >
                {(product.quantity || 1) > 1 && (
                  <Tooltip id={`tooltip-repacking-${product.id}`}>
                    <div
                      className="input_label-tooltip"
                      data-tip="Product is repacked"
                      data-for={`tooltip-repacking-${product.id}`}
                      data-effect="solid"
                      data-place="top"
                    >
                      <ProductRepackingIcon
                        data-tip="Repacked"
                        data-for="product-card-repacking-switch"
                        data-effect="solid"
                        data-place="top"
                        className="product-card_repacking"
                      />
                    </div>
                  </Tooltip>
                )}
                <span>
                  {money(product.price)} / {product.unit}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {!tiny && (
        <>
          <div className="product-card_divider" />
          <div className="product-card_logo">
            {!isCustom && <LogoIcon className="product-card_custom" />}
          </div>
        </>
      )}
    </div>
  )
}

export default memo(ProductCard)
