import React, { memo, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { ICheckout, IUser } from '../../types/user'
import { requestCheckouts } from './usersActions'

interface IOwnProps {
  user: IUser
  branchId: number
  accountId?: number
}

const rounded = (price: number) => Math.round(price * 100) / 100

const UserCheckouts = ({ user, branchId, accountId }: IOwnProps) => {
  const [checkouts, setCheckouts] = useState<ICheckout[]>([])

  const fetchCheckouts = async () => {
    const response = await requestCheckouts(user.id, branchId, accountId)
    setCheckouts(response)
  }

  useEffect(() => {
    if (user.role === 'technician') {
      fetchCheckouts();
    }
  }, [])

  return (
    <div className="user-checkouts">
      {!checkouts.length && (
        <div className="list_placeholder">No checkout requests yet</div>
      )}
      {checkouts.map(checkout => (
        <div key={checkout.id} className="user-checkouts_item">
          <div className="user-checkouts_price">
            ${rounded(checkout.cost)} / {checkout.totalItems} products (
            {checkout.totalUnits} units)
          </div>
          <div className="user-checkouts_date">
            {checkout.asap ? 'ASAP' : 'Standard'},{' '}
            {format(checkout.updatedAt, 'MMM DD h:mm A')}
          </div>
        </div>
      ))}
    </div>
  )
}

export default memo(UserCheckouts)
