import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  ComponentType,
} from 'react'
import { pick, sortBy, find } from 'lodash/fp'
import cn from 'classnames'
import useDebounce from 'react-use/lib/useDebounce'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import useThunkDispatch from '../../common/useThunkDispatch'
import Search from '../../components/Search'
import Button from '../../components/Button'
import GridLayout from '../../components/GridLayout'
import { IHash } from '../../types/common'
import { IBranch, IAccount, IUser } from '../../types/user'
import { IProduct } from '../../types/products'
import Splash from '../../components/Splash'
import ImportDialog from '../../components/ImportDialog'
import { uploadProducts } from '../../accounts/accountsActions'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import useClientTitle from '../../common/useClientTitle'
import { useProductAddModal } from './ProductAdd'
import ProductCard from './ProductCard'
import ProductInfo from './ProductInfo'
import {
  requestInventory,
  requestProductUpsert,
  requestProductRemove,
  IInventoryList,
} from './inventoryActions'
import { SimpleIndex } from '../../common/simpleSearch'

interface IFilter {
  title: string
  options: Array<{
    key: string
    label: string
    className?: string | undefined
  }>
}

const makeFilters = (list: IInventoryList): IFilter[] => [
  {
    title: 'Products',
    options: [
      {
        key: 'all',
        label: 'All',
      },
      {
        key: 'unallocated',
        label: 'Unallocated Only',
      },
      {
        key: 'lowstock',
        label: 'Low Stock',
      },
      ...list.divisions.map((d, idx) => ({
        key: `${d.name}-${d.id}`,
        label: d.name,
        className: idx === 0 ? '-separated' : undefined,
      })),
    ],
  },
]

const Row: ComponentType<ListChildComponentProps> = ({
  data,
  index,
  style,
}) => {
  const p = data.products[index]
  return (
    <div style={style} key={p.id}>
      <ProductCard
        product={p}
        className={cn({
          '-selected': data.product && data.product.id === p.id,
        })}
        onClick={() => data.onClick(p)}
        multiCatalogEnabled={data.multiCatalogEnabled}
      />
    </div>
  )
}

const Inventory: FC<{
  branch: IBranch
  account?: IAccount
  isInternal: boolean
  user: IUser
}> = ({ branch, account, isInternal, user }) => {
  const dispatch = useThunkDispatch()
  const [errors, setErrors] = useState<IHash<string>>({})
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [importProductsOpen, setImportProductsOpen] = useState<boolean>(false)
  const [term, setTerm] = useState<string | undefined>('')
  const [list, setList] = useState<IInventoryList | undefined>(undefined)
  const [filters, setFilters] = useState<IFilter[]>([])
  const [filter, setFilter] = useState<string>('all')
  const [products, setProducts] = useState<IProduct[]>([])
  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [distributors, setDistributors] = useState<string[]>([])
  const inventoyList = useRef<HTMLInputElement>(null)

  // common
  useClientTitle({ branch, account })

  // search
  const productsIndex = useMemo(() => {
    const allProducts = list ? list.products : undefined
    return new SimpleIndex<IProduct>(allProducts ? allProducts[filter] : [], {
      keys: ['name', 'code'],
    })
  }, [list])

  // scroll
  const scrollTop = () => {
    if (!inventoyList.current) return
    inventoyList.current.scrollIntoView({ behavior: 'smooth' })
  }

  // set products sorted
  const selectProducts = useCallback((products: IProduct[]) => {
    setProducts(sortBy(p => p.code.toLowerCase(), products))
  }, [])

  // fetch/refetch catalog
  const loadInventory = async (branch: IBranch) => {
    const list = await dispatch(
      requestInventory(branch.id, account && account.id)
    )
    setList(list)
    setFilters(makeFilters(list))
    setDistributors(list.distributors)
  }

  const erpCatalogs = account?.erpCatalogs || user.account?.erpCatalogs || [];
  const multiCatalogEnabled = erpCatalogs.length > 1;

  // on create new
  const handleCreateNew = useCallback(({ name, code }) => {
    setErrors({})
    setProduct({
      code: code || '',
      name: name || '',
      price: '',
      unit: '',
      inventory: '',
      distributor: null,
      quantity: 1,
      divisions: [],
      custom: true,
    } as any)
  }, [])

  // on use based on product
  const handleUseProduct = useCallback((product: IProduct) => {
    setErrors({})
    setProduct({
      ...pick(['id', 'code', 'name', 'price', 'unit', 'distributor'], product),
      divisions: [],
      quantity: 1,
      custom: true,
    } as any)
  }, [])

  // product add modal
  const [{ open: handleProductAdd }, addProductModal] = useProductAddModal({
    branch,
    account,
    existsProducts: products,
    onCreate: handleCreateNew,
    onUse: handleUseProduct,
  })

  // handle product save
  const handleSave = async (product: IProduct) => {
    const perform = async () => {
      try {
        setErrors({})
        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestProductUpsert(product, branch!)
        )
        if (errors) {
          setErrors(errors)
          scrollTop()
        } else {
          if (!product.id) {
            setProduct(result)
          }
          await loadInventory(branch!)
        }
      } finally {
        setSubmitting(false)
      }
    }

    if (branch) {
      await perform()
    }
  }

  // handle product remove
  const handleRemove = useCallback(
    (product: IProduct) => {
      const perform = async () => {
        try {
          setErrors({})
          setSubmitting(true)
          const errors = await dispatch(requestProductRemove(product, branch!))
          if (!errors) {
            setProduct(undefined)
            loadInventory(branch!)
          } else {
            setErrors(errors)
          }
        } finally {
          setSubmitting(false)
        }
      }

      if (branch) {
        perform()
      }
    },
    [branch, filter]
  )

  // initial load
  useEffect(() => {
    if (branch) {
      loadInventory(branch)
    }
  }, [branch])

  // on filter change
  useEffect(() => {
    if (list) {
      const updatedProduct = product
        ? find({ id: product.id }, list.products.all)
        : undefined
      setProduct(updatedProduct)
      researchData()
      scrollTop()
    }
  }, [list, filter])

  const researchData = () => {
    if (list) {
      const results = term
        ? productsIndex.search(term || '')
        : list.products[filter]

      selectProducts(results)
    }
  }

  // on search
  useDebounce(() => researchData(), 300, [term])

  if (!branch || !list) return <Splash isLoading={true} />

  return (
    <div ref={inventoyList} className="list">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            placeholder="Search"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
        </div>
        <div className="filter_sep" />
        <div className="filter_col -flex">
          <Button
            type="submit"
            caliber="sm"
            className="list_action"
            onClick={() => {
              setProduct(undefined)
              handleProductAdd()
            }}
          >
            Add Product
          </Button>
          {isInternal && (
            <Button
              type="submit"
              caliber="sm"
              className="list_action"
              onClick={() => setImportProductsOpen(true)}
            >
              Import
            </Button>
          )}
        </div>
      </div>
      <GridLayout className="list_grid -wide" offset={185}>
        <div>
          {filters.map(f => (
            <div key={f.title} className="list_filter">
              <div className="list_filter-title">{f.title}</div>
              {f.options.map(option => (
                <div
                  key={option.key}
                  className={cn('list_filter-row', option.className)}
                  onClick={() => setFilter(option.key)}
                >
                  <span
                    className={cn('list_filter-value', {
                      '-selected': option.key === filter,
                    })}
                  >
                    {option.label}
                  </span>
                  {(list.products[option.key] || []).length}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div style={{ height: `100%` }}>
          <div className="list_items" style={{ height: `100%` }}>
            {!products.length && (
              <div className="list_placeholder">No products found</div>
            )}
            {!!products.length && (
              <AutoSizer>
                {size => (
                  <FixedSizeList
                    {...size}
                    itemData={{
                      products,
                      product,
                      onClick: (p: IProduct) => {
                        setErrors({})
                        setProduct(p)
                      },
                      multiCatalogEnabled,
                    }}
                    itemCount={products.length}
                    itemSize={102 + 5}
                    itemKey={index => products[index].id}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
          </div>
        </div>
        <div>
          {!product && (
            <div className="list_placeholder">Select product for details</div>
          )}
          {product && (
            <ProductInfo
              product={product}
              branch={branch}
              account={account}
              loading={submitting}
              divisions={list.divisions}
              distributors={distributors}
              errors={errors}
              onSave={handleSave}
              onRemove={handleRemove}
              onRefetch={() => loadInventory(branch!)}
              onCancel={() => {
                setErrors({})
                setProduct(undefined)
              }}
            />
          )}
        </div>
      </GridLayout>
      {addProductModal}
      {importProductsOpen && (
        <ImportDialog
          close={status => {
            setImportProductsOpen(false)
            if (status === 'done') loadInventory(branch)
          }}
          action={(file: File) => uploadProducts(file, branch.id)}
          templateName="import_division_products_template.csv"
          templateUrl="/import_division_products_template.csv"
          title="Import Products"
        />
      )}
    </div>
  )
}

export default withAccountAndBranch(Inventory)
