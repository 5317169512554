import { forEach } from 'lodash/fp'
import { STORAGE_USER_TOKEN_KEY } from '../types/user'
import store from '../store'
import { clearState } from '../auth/authActions'
import { appVersionSelector } from '../auth/authSelectors'

const mForEach = (forEach as any).convert({ cap: false })

export const API_HOST = '/'

export const query = async (
  queryStr: string,
  variables?: { [name: string]: any },
  files?: { [name: string]: File }
) => {
  const token = localStorage.getItem(STORAGE_USER_TOKEN_KEY)
  const appVersion = appVersionSelector(store.getState() as any)

  const headers: HeadersInit = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'x-app-version': appVersion,
  }
  if (!files) headers['Content-Type'] = 'application/json'

  let body: BodyInit
  if (!files) {
    body = JSON.stringify({ query: queryStr, variables })
  } else {
    body = new FormData()
    body.append('query', queryStr)
    if (variables) body.append('variables', JSON.stringify(variables))
    mForEach((file: File, attr: string) => (body as FormData).append(attr, file), files)
  }

  const response = await fetch('/graphql', { method: 'POST', credentials: 'omit', headers, body })

  if (response.status === 401) {
    store.dispatch(clearState())
    throw new Error('Unauthoruized')
  }

  if (response.status === 412) {
    document.location.reload()
  }

  const { errors, data } = await response.json()

  if (errors) {
    if (typeof errors === 'object') {
      throw new Error(errors[0].message)
    }
    throw new Error(errors.message)
  }

  return data
}
